<template>
	<page>
		<module enable-flex>
			<m-search @search="updateList">
				<c-form>
					<c-form-item type="input" label="项目编号" name="code"></c-form-item>
					<c-form-item type="input" label="项目名称" name="name"></c-form-item>
					<c-form-item type="input" label="施工单位" name="zbqy_name"></c-form-item>
					<c-form-item type="datetime" label="创建时间" start-name="start_date" end-name="end_date"></c-form-item>
				</c-form>
			</m-search>
			
			<m-operate>
				<c-button icon="add" @click="editHandle()">新建项目</c-button>
			</m-operate>
			
			<c-table ref="table">
				<c-table-column
					label="项目状态"
					width="130"
				>
					<template v-slot="{data}">
						<span v-if="projectState[data.state]" :style="{'color': projectState[data.state].color}">{{projectState[data.state].name}}</span>
					</template>
				</c-table-column>
				
				<c-table-column
					label="项目编号"
					name="code"
					width="100"
				></c-table-column>
				
				<c-table-column
					label="项目名称"
					name="name"
					width="200"
				></c-table-column>
				
				<c-table-column
					label="业主单位"
					name="yzdw_name"
					width="250"
				></c-table-column>
				
				<c-table-column
					label="施工单位(中标企业)"
					name="zbqy_name"
					width="250"
				></c-table-column>
				
				<c-table-column
					label="已购买保单"
					width="100"
				>
					<template v-slot="{data}">
						<span v-if="data.bx_count == 0" style="color: #E63633;">未购买</span>
						<span v-else>{{data.bx_count}}份</span>
					</template>
				</c-table-column>
				
				<c-table-column
					label="创建人"
					name="create_user_name"
					width="120"
				></c-table-column>
				
				<c-table-column
					label="创建时间"
					name="create_date"
					width="160"
				></c-table-column>
				
				<c-table-column
					type="button"
					label="操作"
					width="160"
					limit="2"
				>
					<template v-slot="{data}">
						<c-table-button v-if="data.state == 2" @click="editHandle(data)">确认</c-table-button>
						<c-table-button v-if="data.state == 2" @click="rejectProject(data)">驳回</c-table-button>
						<c-table-button v-else-if="data.state == 31" @click="navigateTo('/project_detail', {id: data.id})">审批</c-table-button>
						<c-table-button v-else-if="data.state == 32" @click="navigateTo('/project_detail', {id: data.id})">签署</c-table-button>
						<c-table-button v-else @click="navigateTo('/project_detail', {id: data.id})">详情</c-table-button>
						<c-table-button v-if="data.state == 3 && data.zbdl_money_user_type == 2" @click="biddingHandle(data)">设置中标企业</c-table-button>
						<!-- <c-table-button v-if="data.state == 4">设置购买险种</c-table-button> -->
						<c-table-button v-if="data.state == 4" @click="navigateTo('/contract_create', {projectId: data.id})">上传合同</c-table-button>
						<c-table-button v-if="data.state < 4" @click="deleteHandle(data)">删除</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>
		
		<c-dialog ref="projectEdit" width="600" button-name="确认并提交" @resolve="editSubmitHandle">
			<project-edit ref="projectEditForm"></project-edit>
		</c-dialog>
		
		<c-dialog ref="projectBidding" title="设置中标企业" width="600" button-name="确认并提交" @resolve="biddingSubmitHandle">
			<project-bidding ref="projectBiddingForm"></project-bidding>
		</c-dialog>
		
		<approve ref="approve" @resolve="approveHandle"></approve>
	</page>
</template>

<script>
	import projectEdit from './project_edit_form.vue'
	import projectBidding from './project_bidding_form.vue'
	import approve from '@/components/module/approve.vue'
	import {mapGetters} from 'vuex'
	
	export default {
		components: {
			projectEdit,
			projectBidding,
			approve
		},
		
		props: {
			scene: String
		},
		
		computed: {
			...mapGetters(['projectState'])
		},
		
		mounted() {
			this.updateList();
		},
		
		methods: {
			updateList(data = {}) {
				if (this.scene == 'approve') {
					data.states = [2, 31, 32];
				}
				this.$refs.table.load({
					url: '/project/list',
					data
				});
			},
			
			editHandle(data) {
				this._edit = !!data;
				if (data) {
					this.$refs.projectEditForm.edit(data.id);
				} else {
					this.$refs.projectEditForm.add();
				}
				this.$refs.projectEdit.open({
					title: data ? '审批项目' : '新建项目'
				});
			},
			
			editSubmitHandle(stop, next) {
				stop();
				
				this.$refs.projectEditForm.submit().then(() => {
					next();
					this.$message({
						text: '提交成功'
					});
					this.$refs.table.update(this._edit ? 0 : 1);
				});
			},
			
			biddingHandle(data) {
				this.$refs.projectBiddingForm.edit(data.id);
				this.$refs.projectBidding.open();
			},
			
			biddingSubmitHandle(stop, next) {
				stop();
				
				this.$refs.projectBiddingForm.submit().then(() => {
					next();
					this.$message({
						text: '提交成功'
					});
					this.$refs.table.update(this._edit ? 0 : 1);
				});
			},
			
			deleteHandle(data) {
				this.$confirm({
					text: '确定要删除此项目吗？',
					resolve: () => {
						this.request({
							url: '/project/delete',
							data: {
								project_id: data.id
							},
							loading: true,
							success: () => {
								this.$message({
									text: '删除成功'
								});
								this.$refs.table.update();
							}
						});
					}
				});
			},
			
			rejectProject(data) {
				this._approveData = data;
				this.$refs.approve.reject();
			},
			
			approveHandle(code, remark, stop, next) {
				stop && stop();
				
				this.request({
					url: '/project/confirm',
					data: {
						project_id: this._approveData.id,
						state: code ? 3 : 1,
						reject_remark: remark
					},
					loading: true,
					success: data => {
						next();
						this.$message({
							text: '审批成功'
						});
						this.$refs.table.update();
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>