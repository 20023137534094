<template>
	<project-list scene="approve"></project-list>
</template>

<script>
	import projectList from './project_list_pc.vue'
	
	export default {
		name: 'project_approve',
		
		components: {
			projectList
		}
	};
</script>

<style lang="stylus" scoped>
</style>